const ShoppingCart = require("./../../Core/ShoppingCart/shopping_cart");

if (typeof (VTE) === 'undefined')
    VTE = {};

VTE.GlobalSimulatorMgr = function (form, opTypeContainer, catContainer, eligibleContainer, inputsContainer, conditionsContainer, catInfo) {
    var pthis = this;

    this.form = form;
    this.opTypeContainer = opTypeContainer;
    this.catContainer = catContainer;
    this.eligibleContainer = eligibleContainer;
    this.inputsContainer = inputsContainer;
    this.conditionsContainer = conditionsContainer;
    this.catInfo = catInfo;
    this.precaInfos = $('#precaInfos');
    this.isEntreprise = $('#is_entreprise');
    this.isBailleur = $('#is_bailleur');
    this.submitInput = $('input[type=submit]');

    this.fzForbiddenConcurrents = ['Antargaz', 'Sogasud', 'Finagaz'];


    /**
     * Renvoie la valeur actuelle d'input donné
     * @param {string} inputName
     * @returns {string} la valeur actuelle de l'input
     */

    (function ($) {
        $.fn.getInputVal = function () {
            var input = this.find('input[type=radio]:checked, input[type!=radio], select').filter(':visible, :not(:disabled)');
            if (input.length == 0 || input.val() === '')
                return null;
            return input.val();
        };
    })(jQuery);
    (function ($) {
        $.fn.setInputVal = function (inputVal) {
            this.find('input[type!=radio], select').val(inputVal);
            this.find('input[type=radio]').prop('checked', false).filter('[value="' + inputVal + '"]').prop('checked', true);
            return this;
        };
    })(jQuery);

    /**
     * On veut dans certains cas déclencher l'affichage d'une popup qui indique que
     * la configuration actuelle rend les travaux non éligibles.
     *
     * On fait cela de la manière la plus simple possible: un tableau de
     * couples (fonction d'éligibilité => renvoi faux si non éligible, message à afficher en cas d'inégibilité)
     */
    this.eligibilityConditions = [
        {
            cond: function () {
                // Chaudière haute perforamnce collective P3 (129) et sa version SE (131)
                if ($.inArray(pthis.getSelectedWorkId(), [129, 131]) === -1)
                    if (pthis.getSelectedWorkId() != 129 && pthis.getSelectedWorkId() != 131)
                        return true;

                var pacPower = $('#P3_PAC_POWER').getInputVal();
                var chauffPower = $('#P3_CHAUFF_TOT_POWER').getInputVal();
                if (pacPower === null || chauffPower === null)
                    return true;

                return pacPower < 0.4 * chauffPower;
            },
            message: "<p>La puissance des pompes à chaleur installées est supérieure à 40% de la puissance de la nouvelle chaufferie.</p><p>En conséquence vos travaux ne sont pas éligibles à l'obtention de certificats d'économie d'énergie.</p>"
        },
        {
            cond: function () {
                // Récupérateur de chaleur à condensation (253)
                if ($.inArray(pthis.getSelectedWorkId(), [253]) === -1)
                    return true;

                var pacPower = $('#P3_TOT_NEW_PAC_POWER').getInputVal();
                var chauffPower = $('#P3_CHAUFF_TOT_POWER').getInputVal();
                if (pacPower === null || chauffPower === null)
                    return true;

                return pacPower < 0.4 * chauffPower;
            },
            message: "<p>La puissance des pompes à chaleur nouvellement installées est supérieure à 40% de la puissance de la nouvelle chaufferie.</p><p>En conséquence vos travaux ne sont pas éligibles à l'obtention de certificats d'économie d'énergie.</p>"
        },
        {
            cond: function () {
                // Travaux non éligibles en appartement : Chaudière biomasse P2 et P3 (ID 14, 114), AICB P3 (ID 113), CESI P2(20), SSC P2(21), Régulation P2(10)
                if ($.inArray(pthis.getSelectedWorkId(), [114, 10, 14, 20, 21, 113]) === -1)
                    return true;

                var log = $('#LOG').getInputVal();
                if (log == 2 || log == 3)
                    return false;

                return true;
            },
            message: "<p>Ces travaux ne sont pas éligibles en Appartement ou en Immeuble Collectif.</p>"
        },
        {
            cond: function () {
                // Travaux non éligibles en Maison : Isolation toitures terrasses P2 (ID 2), Iso réseau chauffage P2(ID 74), Iso ECS P2 (ID 75)
                if ($.inArray(pthis.getSelectedWorkId(), [2, 74, 75]) === -1)
                    return true;

                var log = $('#LOG').getInputVal();
                if (log == 1)
                    return false;

                return true;
            },
            message: "<p>Ces travaux ne sont pas éligibles en Maison individuelle.</p>"
        },
        {
            cond: function () {
                // Travaux non définis en immeuble collectif: Chaudière haute perf (ID 112)
                if ($.inArray(pthis.getSelectedWorkId(), [112]) === -1)
                    return true;
                return $('#LOG').getInputVal() != 3;
            },
            message: "<p>Ces travaux ne sont pas éligibles pour un immeuble collectif.</p><p>Choisissez la chaudière collective à haute performance énergétique (BAR-TH-107).</p>"
        },
        {
            cond: function () {
                // Rénovation d'éclairage extérieur
                if (pthis.getSelectedWorkId() != 171)
                    return true;

                var effLight = $('#P3_EFFLIGHT').getInputVal();
                var uler = $('#P3_ULOR').getInputVal();
                var ulr = $('#P3_ULR').getInputVal();
                if ((effLight >= 90 && uler <= 1) || (ulr <= 3))
                    return true;
                else if ((effLight >= 70 && uler <= 10) || (ulr <= 15))
                    return true;
                else return false;
            },
            message: "<p>Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées</p>"
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 204)
                    return true;

                var typeCaisson = $('#TYPE_CAISSON').getInputVal();
                var log = $('#LOG').getInputVal();
                if (log == 1 && typeCaisson == 2)
                    return false;
                else return true;
            },
            message: "<p>Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées</p>"
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [219, 220]) === -1)
                    return true;

                var p3EligE = $('#P3_ELIG_E').getInputVal();
                var moyenne = $('#P3_TRA_E').getInputVal();
                if (p3EligE >= 2 || moyenne >= 116)
                    return false;
                else return true;
            },
            message: "<p>Ces travaux ne sont pas éligibles.Le niveau d’émissions de CO2 de chaque véhicule doit être inférieur à 116 gCO2/km.</p>"
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [202, 206]) === -1)
                    return true;

                var log = $('#LOG').getInputVal();
                if (log == 1)
                    return false;
                else return true;
            },
            message: "<p>Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées</p>"
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [111]) === -1)
                    return true;

                var quotationDate = pthis.inputsContainer.find("input[name*=quotation_date]");
                var typePacVal = $('#P3_TYPE_PAC').getInputVal();
                var qDVal = quotationDate.length > 0 ? quotationDate.val().replace(' / ', '-').replace(' / ', '-') : null;
                var val = $('#P3_ETAS').getInputVal();
                var result = /^(\d{2})-(\d{2})-(\d{4})$/.exec(qDVal);
                if (result) {
                    var quotationDateVal = new Date(
                        parseInt(result[3], 10),
                        parseInt(result[2], 10) - 1,
                        parseInt(result[1], 10)
                    );
                }

                var date1 = new Date('09-26-2015');
                var date2 = new Date('09-26-2017');

                if (quotationDateVal >= date1 && quotationDateVal < date2) {
                    if (val != null) {
                        if (typePacVal == 0) {
                            return val >= 117;
                        } else if (typePacVal == 1) {
                            return val >= 102;
                        }
                    }
                }

                if (quotationDateVal >= date2) {
                    if (val != null) {
                        if (typePacVal == 0) {
                            return val >= 126;
                        } else if (typePacVal == 1) {
                            return val >= 111;
                        }
                    }
                }
                ;
            },
            message: "<p>Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiqué (ETAS trop faible)</p>"
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [142]) === -1)
                    return true;

                var quotationDate = pthis.inputsContainer.find("input[name*=quotation_date]");
                var typePacVal = $('#P3_TYPE_PAC').getInputVal();
                var puissanceVal = $('#P3_PAC_COL_POWER').getInputVal();
                var qDVal = quotationDate.length > 0 ? quotationDate.val().replace(' / ', '-').replace(' / ', '-') : null;
                var val = $('#P3_ETAS').getInputVal();
                var result = /^(\d{2})-(\d{2})-(\d{4})$/.exec(qDVal);
                if (result) {
                    var quotationDateVal = new Date(
                        parseInt(result[3], 10),
                        parseInt(result[2], 10) - 1,
                        parseInt(result[1], 10)
                    );
                }

                var date1 = new Date('09-26-2015');
                var date2 = new Date('09-26-2017');
                if (puissanceVal != null && puissanceVal <= 400) {
                    if (quotationDateVal >= date1 && quotationDateVal < date2) {
                        if (val != null) {
                            if (typePacVal == 0) {
                                return val >= 117;
                            } else if (typePacVal == 1) {
                                return val >= 102;
                            }
                        }
                    }

                    if (quotationDateVal >= date2) {
                        if (val != null) {
                            if (typePacVal == 0) {
                                return val >= 126;
                            } else if (typePacVal == 1) {
                                return val >= 111;
                            }
                        }
                    }
                    ;
                }
            },
            message: "<p>Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées</p>"
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [137]) === -1)
                    return true;

                var quotationDate = pthis.inputsContainer.find("input[name*=quotation_date]");
                var typePacVal = $('#P3_TYPE_PAC').getInputVal();
                var puissanceVal = $('#P3_PAC_POWER').getInputVal();
                var qDVal = quotationDate.length > 0 ? quotationDate.val().replace(' / ', '-').replace(' / ', '-') : null;
                var val = $('#P3_ETAS').getInputVal();
                var result = /^(\d{2})-(\d{2})-(\d{4})$/.exec(qDVal);
                if (result) {
                    var quotationDateVal = new Date(
                        parseInt(result[3], 10),
                        parseInt(result[2], 10) - 1,
                        parseInt(result[1], 10)
                    );
                }

                var date1 = new Date('09-26-2015');
                var date2 = new Date('09-26-2017');
                if (puissanceVal != null && puissanceVal <= 400) {
                    if (quotationDateVal >= date1 && quotationDateVal < date2) {
                        if (val != null) {
                            if (typePacVal == 0) {
                                return val >= 117;
                            } else if (typePacVal == 1) {
                                return val >= 102;
                            }
                        }
                    }

                    if (quotationDateVal >= date2) {
                        if (val != null) {
                            if (typePacVal == 0) {
                                return val >= 126;
                            } else if (typePacVal == 1) {
                                return val >= 111;
                            }
                        }
                    }
                    ;
                }
            },
            message: "<p>Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées</p>"
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [143]) === -1)
                    return true;

                var quotationDate = pthis.inputsContainer.find("input[name*=quotation_date]");
                var typePacVal = $('#P3_TYPE_PAC').getInputVal();
                var puissanceVal = $('#P3_TOT_NEW_PAC_POWER').getInputVal();
                var qDVal = quotationDate.length > 0 ? quotationDate.val().replace(' / ', '-').replace(' / ', '-') : null;
                var val = $('#P3_ETAS').getInputVal();
                var result = /^(\d{2})-(\d{2})-(\d{4})$/.exec(qDVal);
                if (result) {
                    var quotationDateVal = new Date(
                        parseInt(result[3], 10),
                        parseInt(result[2], 10) - 1,
                        parseInt(result[1], 10)
                    );
                }

                var date1 = new Date('09-26-2015');
                var date2 = new Date('09-26-2017');
                if (puissanceVal != null && puissanceVal <= 400) {
                    if (quotationDateVal >= date1 && quotationDateVal < date2) {
                        if (val != null) {
                            if (typePacVal == 0) {
                                return val >= 117;
                            } else if (typePacVal == 1) {
                                return val >= 102;
                            }
                        }
                    }

                    if (quotationDateVal >= date2) {
                        if (val != null) {
                            if (typePacVal == 0) {
                                return val >= 126;
                            } else if (typePacVal == 1) {
                                return val >= 111;
                            }
                        }
                    }
                    ;
                }
            },
            message: "<p>Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées</p>"
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [257]) === -1)
                    return true;

                var enginePowerVal = $('#P3_VEV').getInputVal();
                if (enginePowerVal > 1000)
                    return false;

            },
            message: "<p>La puissance nominale du moteur doit être inférieure à 1000kW</p>"
        }
    ];

    this.hideInputsConditions = [
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [322]) === -1)
                    return true;
                return false;
            },
            hideList: ['RRC_ERR']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [306]) === -1)
                    return false;

                var type = $('#PROPELLER_TYPE').getInputVal();
                if (type == 1)
                    return true;
            },
            hideList: ['POWER_RANGE', 'PS_KM']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [306]) === -1)
                    return false;

                var type = $('#PROPELLER_TYPE').getInputVal();
                if (type == 2)
                    return true;
            },
            hideList: ['LIFT_RANGE', 'AM_KM_TONNAGE']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [115, 116]) === -1)
                    return false;
                var log = $('#LOG').getInputVal();
                return log == 1;
            },
            hideList: ['NAPP', 'TCH']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() == 335)
                    if ($('#ETAS_POWER').getInputVal() == 0) {
                        return false;
                    }
                return true;
            },
            hideList: ['PAC_TYPE', 'ETAS_COLD', 'ETAS_CHAUFF']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() == 335)
                    if ($('#ETAS_POWER').getInputVal() == 1) {
                        return false;
                    }
                return true;
            },
            hideList: ['SEER', 'SCOP']
        },
        {
            cond: function () {  // système de condensation frigorifique à haute efficacité
                if ($.inArray(pthis.getSelectedWorkId(), [139, 250]) === -1)
                    return false;
                var scond = $('#SCOND').getInputVal();
                return scond == 0 || scond == 2;
            },
            hideList: ['DeltaTypeCond']
        },
        {
            cond: function () {  // système de condensation frigorifique à haute efficacité
                if ($.inArray(pthis.getSelectedWorkId(), [139, 250]) === -1)
                    return false;
                var scond = $('#SCOND').getInputVal();
                return !(scond == 2);
            },
            hideList: ['DTEAU']
        },
        {
            cond: function () {  // système de condensation frigorifique à haute efficacité
                if ($.inArray(pthis.getSelectedWorkId(), [139, 250]) === -1)
                    return false;
                var deltaType = $('#DeltaTypeCond').getInputVal();
                var scond = $('#SCOND').getInputVal();
                return !(scond == 1 && deltaType == 1);
            },
            hideList: ['DTASEC']
        },
        {
            cond: function () {  // système de condensation frigorifique à haute efficacité
                if ($.inArray(pthis.getSelectedWorkId(), [139, 250]) === -1)
                    return false;
                var deltaType = $('#DeltaTypeCond').getInputVal();
                var scond = $('#SCOND').getInputVal();
                return !(scond == 1 && deltaType == 2);
            },
            hideList: ['DTAHUM']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [129, 131]) === -1)
                    return false;
                var otherEq = $('#P3_OTHER_EQ').getInputVal();
                var chPowerVal = $('#P3_CH_POWER').getInputVal();
                if (otherEq == 0)
                    $('#P3_TOT_NEW_CH_POWER').setInputVal(chPowerVal);
                return (otherEq == 0);
            },
            hideList: ['P3_TOT_NEW_CH_POWER']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [129, 131]) === -1)
                    return false;
                var haspacPower = $('#P3_HAS_PAC_POWER').getInputVal();
                return (haspacPower == 0);
            },
            hideList: ['P3_PAC_POWER']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [142,333]) === -1)
                    return false;
                var haspac = $('#P3_HAS_PAC').getInputVal();
                var pacColPower = $('#P3_PAC_COL_POWER').getInputVal();
                if (haspac == 0)
                    $('#P3_TOT_NEW_PAC_POWER').setInputVal(pacColPower);
                return (haspac == 0);
            },
            hideList: ['P3_TOT_NEW_PAC_POWER']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [156]) === -1)
                    return false;

                var logVal = $('#LOG').getInputVal();
                if (logVal != 3)
                    $('#P3_NHYDRO').setInputVal(1);
                return logVal != 3;
            },
            hideList: ['P3_NHYDRO']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 204)
                    return false;

                var logVal = $('#LOG').getInputVal();
                return logVal == 1;
            },
            hideList: ['NAPP']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 208)
                    return false;

                var typeEclairageVal = $('#P3_ECLAIRAGE_TYPE').getInputVal();
                return typeEclairageVal == 1;
            },
            hideList: ['P3_NBR_LUM', 'P3_TYPE_LED_GESTION']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 208)
                    return false;

                var typeEclairageVal = $('#P3_ECLAIRAGE_TYPE').getInputVal();
                return typeEclairageVal != 1;
            },
            hideList: ['P3_NBR_LAMP']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 227)
                    return false;

                var log = $('#LOG').getInputVal();
                return log == 1;
            },
            hideList: ['NAPP']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 227)
                    return false;

                var log = $('#LOG').getInputVal();
                if (log != 1) {
                    $('#SH').setInputVal(20);
                }
                return log != 1;
            },
            hideList: ['SH']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 233)
                    return false;

                var type = $('#P3_TER_HYDRO_TYPE').getInputVal();
                if (type == 1 || type == 2) {
                    $('P3_TER_SEC2').setInputVal(1);
                }
                return type == 1 || type == 2;
            },
            hideList: ['P3_TER_SEC1']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 233)
                    return false;

                var type = $('#P3_TER_HYDRO_TYPE').getInputVal();
                if (type == 3 || type == 4) {
                    $('P3_TER_SEC1').setInputVal(1);
                }
                return type == 0 || type == 3 || type == 4;
            },
            hideList: ['P3_TER_SEC2']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 236)
                    return false;

                var mat = $('#P3_TRA_MAT').getInputVal();
                if (mat == 1) {
                    $('#P3_TRA_P').setInputVal(1);
                    $('#P3_TRA_KILOM').setInputVal(0);
                }
                return mat == 0 || mat == 1;
            },
            hideList: ['P3_TRA_P', 'P3_TRA_KILOM']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 236)
                    return false;

                var mat = $('#P3_TRA_MAT').getInputVal();
                if (mat == 2) {
                    $('#P3_T_KM').setInputVal(0);
                    $('#P3_TRA_M').setInputVal(1);
                }
                return mat == 2;
            },
            hideList: ['P3_T_KM', 'P3_TRA_M']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 213)
                    return false;
                return true;
            },
            hideList: ['P3_CUSAGE']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 135)
                    return false;
                return true;
            },
            hideList: ['LUMGEST']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 152)
                    return false;
                return true;
            },
            hideList: ['P3_PRESSE_TYPE']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 310)
                    return false;
                var diametre = $('#DIAMETRE').getInputVal();
                if (diametre == 1 && $('#SISO').val() == "") {
                    $('#SISO').setInputVal(0);
                }
                return diametre == 1;
            },
            hideList: ['SISO']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 310)
                    return false;
                var diametre = $('#DIAMETRE').getInputVal();
                if (diametre == 2 && $('#LISO').val() == "") {
                    $('#LISO').setInputVal(0);
                }
                return diametre == 2;
            },
            hideList: ['LISO']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [137, 111, 143, 239]) === -1)
                    return false;
                if (pthis.getSelectedWorkId() == 137) {
                    var puissance = $('#POWER_THERMPAC').getInputVal();
                    if (puissance != null) {
                        if (puissance != 1) {
                            return false ;
                        }
                        return true;
                    } else {
                        var puissance = $('#P3_PAC_POWER').getInputVal();
                        return puissance != null && puissance <= 400
                    }
                } else if (pthis.getSelectedWorkId() == 111) {
                    return true;
                } else if (pthis.getSelectedWorkId() == 143) {
                    return true;
                } else if (pthis.getSelectedWorkId() == 239) {
                    return true;
                }
            },
            hideList: ['P3_COPE']
        },

        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [137, 111, 143, 239]) === -1)
                    return false;

                if (pthis.getSelectedWorkId() == 137) {
                    var puissance = $('#P3_PAC_POWER').getInputVal();
                    return puissance != null && puissance > 400;
                } else if (pthis.getSelectedWorkId() == 143) {
                    var puissance = $('#P3_TOT_NEW_PAC_POWER').getInputVal();
                    return puissance != null && puissance > 400;
                } else if (pthis.getSelectedWorkId() == 239) {
                    var puissance = $('#P3_TOT_NEW_PAC_POWER').getInputVal();
                    return puissance != null && puissance > 400;
                } else if (pthis.getSelectedWorkId() == 11) {
                    return true;
                }
            },
            hideList: ["P3_ETAS"]
        },

        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [130, 137, 193, 143, 244]) === -1)
                    return false;

                $('#P3_SCHAUF').setInputVal(0);

                return true;
            },
            hideList: ['P3_SCHAUF']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [143, 239]) === -1)
                    return false;
                var puissance = $('#P3_TOT_NEW_PAC_POWER').getInputVal();
                return puissance != null && puissance <= 400;
            },
            hideList: ['P3_PAC_COPE']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [312,314]) === -1)
                    return false;

                var $type = $('#TYPE_INSTALL_ISO').getInputVal();
                if($type == 1)
                {
                    $('#NEPI').setInputVal(0);
                }
                return $type == 1;
            },
            hideList: ['NEPI']
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [312,314]) === -1)
                    return false;

                var $type = $('#TYPE_INSTALL_ISO').getInputVal();
                if($type == 2)
                {
                    $('#NHI').setInputVal(0);
                }
                return $type == 2;
            },
            hideList: ['NHI', "DN_CANALISATION"]
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [313]) === -1)
                    return false;

                var $type = $('#MOD_INSTALL').getInputVal();
                return $type == 1;
            },
            hideList: ["USAGE_BAT2"]
        },
        {
            cond: function () {
                if ($.inArray(pthis.getSelectedWorkId(), [313]) === -1)
                    return false;

                var $type = $('#MOD_INSTALL').getInputVal();
                return $type == 2;
            },
            hideList: ["USAGE_BAT"]
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 231)
                    return false;
                return true;
            },
            hideList: ['SEC7']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 206)
                    return false;
                return true;
            },
            hideList: ['TYPE_CAISSON']
        },
        {
            cond: function () {
                if (pthis.getSelectedWorkId() != 219)
                    return false;
                $('select[name*="CAT_VEHICLE"]').find('option[value="5"]').hide();
                $('select[name*="CAT_VEHICLE"]').find('option[value="6"]').hide();
                return true;
            },
            hideList: ['NBR_VEHICLE_2025_2026','NBR_VEHICLE_2027']
        },
        {
            cond: function () {
                var today = new Date();
                var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                if (date >= '2021-03-15') {
                    $('#ERR').setInputVal(2);
                    return true;
                }

                if ($.inArray(pthis.getSelectedWorkId(), [322]) === -1)
                    return false;

                var type = $('#RRC_ERR').getInputVal();
                if (type != 2) {
                    $('#ERR').setInputVal(1);
                    return true;
                }

            },
            hideList: ['ERR']
        },
    ];

    this.opTypeContainer = opTypeContainer;
    this.catContainer = catContainer;
    this.eligibleContainer = eligibleContainer;
    this.inputsContainer = inputsContainer;
    this.conditionsContainer = conditionsContainer;

    this.hideContainer = function (container) {
        container.setInputVal(null);
        if (container == this.catContainer) {
            container.addClass('fields-disabled').find('select').attr('disabled', true);
        } else if (container == this.eligibleContainer) {
            $('.form-part-2').hide();
        } else {
            container.hide();
        }

    };

    this.showContainer = function (container) {
        if (container == this.catContainer) {
            container.removeClass('fields-disabled').find('select').removeAttr('disabled');
        } else if (container == this.eligibleContainer) {
            $('.form-part-2').slideDown();
            $('.meta-footer').css({
                'margin-top': '0'
            });
        } else {
            container.show();
        }
    };

    this.filterBlock = function (container, idList) {
        var select = container.find('select');
        if (typeof (select.get(0).initOptions) === 'undefined') {
            select.get(0).initOptions = select.find('option');
        }
        select.find('option').each(function () {
            if ($(this).attr('value') != '') {
                $(this).remove();
            }
        });
        select.get(0).initOptions.each(function () {
            var id = $(this).attr('value');
            if (id == '') return true;
            if (id in idList) {
                select.append($(this));
            }
        });
    };

    this.refresh = function () {
        if (!this.opTypeContainer && !this.catContainer)
            return;

        var selectedOpType = opTypeContainer.getInputVal();
        if (selectedOpType === null) {
            this.hideContainer(this.catContainer);
            this.hideContainer(this.eligibleContainer);
            this.refreshInputs();
            this.refreshConditions();
            return;
        }

        var togglePreca = (this.isEntreprise.getInputVal() != 1 && this.isBailleur.getInputVal() != 1)
        this.togglePreca(selectedOpType === 'residentiel' && togglePreca);
        this.showContainer(this.catContainer);
        var acceptedCats = this.catInfo[selectedOpType].categories;
        this.filterBlock(catContainer, acceptedCats);

        var selectedCatId = catContainer.getInputVal();
        if (selectedCatId === null) {
            this.hideContainer(this.eligibleContainer);
            this.refreshInputs();
            this.refreshConditions();
            this.toggleEnterpriseBailleurQuestions(selectedOpType === 'residentiel');
            return;
        }

        this.showContainer(this.eligibleContainer);

        var acceptedWorks = acceptedCats[selectedCatId].works;
        this.filterBlock(this.eligibleContainer, acceptedWorks);

        this.refreshInputs();
        this.toggleEnterpriseBailleurQuestions(selectedOpType === 'residentiel');
        this.refreshConditions();
        this.hideConditionalInputs();
        this.checkEligibility();
        this.toggleEnrQuestions();
        this.toggleEnrCdpQuestions();
        this.toggleVehicleParcTypeQuestion();
        this.toggleRaccordResChaleurQuestion();
    };

    this.togglePreca = function (show) {
        if (show) {
            this.precaInfos.show();
        } else {
            this.precaInfos.hide();
            this.precaInfos.find('[name*=nb_hab_foyer]').val(2);
            this.precaInfos.find('[name*=revenu_foyer]').val(0);
        }
    };

    this.toggleEnterpriseBailleurQuestions = function (show) {
        this.isEntreprise.toggle(show);
        this.isEntreprise.find('input')
            .removeAttr('disabled')

        this.isBailleur.toggle(show);
        this.isBailleur.find('input')
            .removeAttr('disabled')
    };

    this.verifyEntrepriseValue = function () {
        var selectedOpType = opTypeContainer.getInputVal();
        if (selectedOpType != "residentiel") {
            this.isEntreprise.setInputVal(1);
        }
    };

    this.refreshInputs = function () {
        this.inputsContainer.find('.work-input').find('input, select').attr('disabled', 'disabled');
        var workInputs = this.getSelectedWorkInputs();

        if (workInputs.length === 0) {
            this.inputsContainer.hide();
        } else {
            if (workInputs.indexOf("COTATIONDATE") == -1) {
                workInputs.push("COTATIONDATE");
            }

            if (workInputs.indexOf("is_entreprise") != -1) {
                workInputs.splice("is_entreprise", 1);
            }

            this.inputsContainer.find('.work-input').each(function () {
                var id = $(this).attr('id');
                var show = $.inArray(id, workInputs) !== -1 || $.inArray(id, ['ag_offer_type', 'mwh_price', 'is_bailleur', 'oblige_id','etiquette_energetique_logement']) !== -1;
                $(this).toggle(show);
                if ($('[name*="operation_type"]').val() != 'residentiel' && id == 'etiquette_energetique_logement'){
                    $(this).hide();
                }
                if (show)
                    $(this).find('input, select').removeAttr('disabled');
            });

            var leftInputs = workInputs.slice(0, workInputs.length / 2);
            var rightInputs = workInputs.slice(workInputs.length / 2, workInputs.length);

            // Trier les inputs suivant l'ordre des inputs des travaux
            for (var i = 0; i < leftInputs.length; i++) {
                var field = $('#' + leftInputs[i]);
                field.detach().appendTo(this.inputsContainer.find('#work-list-1'));

            }
            for (var i = 0; i < rightInputs.length; i++) {
                var field = $('#' + rightInputs[i]);
                field.detach().appendTo(this.inputsContainer.find('#work-list-2'));
            }

            this.inputsContainer.show();
        }
    };

    this.refreshConditions = function () {
        var targetArea = this.conditionsContainer.find('#conditions_html');
        var selectedWorkId = this.getSelectedWorkId();
        if (selectedWorkId === null) {
            this.hideContainer(this.conditionsContainer);
            targetArea.html('');
            return;
        }
        var opType = this.opTypeContainer.getInputVal();
        var catId = this.catContainer.getInputVal();
        var conditionsHtml = this.catInfo[opType].categories[catId].works[selectedWorkId].conditions_html;

        // Si pas de conditions d'éligibilité, on masque la zone
        if (!conditionsHtml) {
            this.conditionsContainer.hide();
            targetArea.html('');
            return;
        }
        $('#conditions_container').html(conditionsHtml);
        this.showContainer(this.conditionsContainer);
    };

    this.getSelectedWorkInputs = function () {
        var selectedWorkId = this.getSelectedWorkId();
        if (selectedWorkId === null) {
            return [];
        }

        var opType = this.opTypeContainer.getInputVal();
        var catId = this.catContainer.getInputVal();

        return this.catInfo[opType].categories[catId].works[selectedWorkId].input_list;
    };

    this.hideConditionalInputs = function () {
        for (var i = 0; i < this.hideInputsConditions.length; i++) {
            var cond = this.hideInputsConditions[i].cond;
            var hideList = this.hideInputsConditions[i].hideList;
            var doHide = cond();
            for (var j = 0; j < hideList.length; j++) {
                var inputName = hideList[j];
                if (inputName == 'RRC_ERR')
                    continue;
                // On ne veut pas faire de show/hide, sinon les champs ne sont pas postés
                // => et on a une erreur de validation
                var opts = doHide ? {visibility: 'hidden', position: 'absolute'} : {
                    visibility: 'visible',
                    position: 'static'
                };
                $('#' + inputName).css(opts);
            }
        }
    };

    /**
     * Récupère l'id des travaux sélectionnés
     * @returns int l'identifiant des travaux sélectionnés
     */
    this.getSelectedWorkId = function () {
        var selectedWorkId = parseInt(this.eligibleContainer.getInputVal());
        return isNaN(selectedWorkId) ? null : selectedWorkId;
    };

    this.checkEligibility = function () {
        var popupContainer = $('<div>');
        var displayPopup = false;
        for (var i = 0; i < this.eligibilityConditions.length; i++) {

            var condObj = this.eligibilityConditions[i];
            if (condObj.cond() !== false) {
                continue;
            }

            displayPopup = true;
            popupContainer.append(condObj.message);
        }

        if (displayPopup) popupContainer.dialogMp();
    };

    this.toggleEnrQuestions = function () {
        // Si EN(E)R ne fait pas partie des questions des travaux sélectionnés, il ne faut rien faire
        if ($.inArray('ENR', this.getSelectedWorkInputs()) === -1 && $.inArray('ENER', this.getSelectedWorkInputs()) === -1) {
            return;
        }

        // L'input de type d'énergie peut s'appeler ENR ou ENER, en fonction des travaux
        var enr = $.inArray('ENR', this.getSelectedWorkInputs()) !== -1 ? $('#ENR') : $('#ENER');
        var enrComb = $('#ENR_COMB');
        var fzConcurrent = $('#fz_concurrent');

        enrComb.find('input, select').removeAttr('disabled');
        fzConcurrent.find('input, select').removeAttr('disabled');
        enrComb.detach().insertBefore(this.inputsContainer.find('.work-input:last'));
        fzConcurrent.detach().insertAfter(enrComb);

        if (enrComb.length > 0) {
            enrComb.before(enr);
            enr.show();
            enr.find('input, select').removeAttr('disabled');
        }

        var enrVal = enr.getInputVal();
        if (!enrVal || enrVal == 1) {
            // $(enrComb, fzConcurrent).hide().each(function () {
                // $(this).find('input:radio').removeAttr('checked');
                // $(this).find('select').val('');
            // });
            enrComb.hide();
        } else if (enrVal == 2) {
            enrComb.show();
        }
        ;

        var enrCombVal = enrComb.getInputVal();
        if (enrCombVal == 2) {
            fzConcurrent.show();
        } else {
            fzConcurrent.hide().setInputVal('');
        }

        // Mettons à jour les champs cachés 'client finagaz nouveau/existant', en fonction du concurrent
        var concurrent = $('select[name*=fz_concurrent]').val();
        var newClient = $('input[name*=new_client]');
        var existingClient = $('input[name*=existing_client]');
        // if(concurrent == '') {
        //     existingClient.val(0);
        //     newClient.val(0);
        // }
        // else {
        //     existingClient.val(concurrent === 'Finagaz' ? 1 : 0);
        //     newClient.val(enr == 2 && ((enrComb == 2 && $.inArray(concurrent, pthis.fzForbiddenConcurrents) === -1)) ? 1 : 0);
        // }
    };

    this.toggleEnrCdpQuestions = function () {
        if ($.inArray('CDP_ENR', this.getSelectedWorkInputs()) === -1) {
            return;
        }

        if (!$('#CDP_ENR').find('input:checked').val() || $('#CDP_ENR').find('input:checked').val() == 1) {
            $('#CDP_ENR_COMB').hide();
            $('#IS_COND_CHAUD_TERTIAIRE').hide();
        } else {
            $('#CDP_ENR_COMB').show();
        }
    };

    // Enregistrement des triggers de rafraichissement
    var refreshTriggers = this.opTypeContainer.find('select').add(this.catContainer.find('select')).add(this.eligibleContainer.find('select')).add(this.inputsContainer.find('input, select'));
    refreshTriggers.change(function () {
        pthis.refresh();
    });

    $(document).ready(function () {
        pthis.form.find('input[type=text][data-unit]').each(function () {
            $(this).attr('placeholder', $(this).data('unit'));
        });
    });

    //TRA-EQ-114
    function showNbrVehicle() {
        $('#NBR_VEHICLE_2025_2026, #NBR_VEHICLE_2027').css({'visibility':'visible', 'position':'relative'});
        $('#P3_NBR_CAR').css({'visibility':'hidden', 'position':'absolute'}).setInputVal(0);
        $('select[name*="CAT_VEHICLE"]').find('option[value="5"]').show();
        $('select[name*="CAT_VEHICLE"]').find('option[value="6"]').show();
        $('select[name*="CAT_VEHICLE"]').find('option[value="3"]').hide();
        $('select[name*="CAT_VEHICLE"]').find('option[value="4"]').hide();
    }
    function hideNbrVehicle() {
        $('#NBR_VEHICLE_2025_2026, #NBR_VEHICLE_2027').css({'visibility':'hidden', 'position':'absolute'}).setInputVal(0);
        $('#P3_NBR_CAR').css({'visibility':'visible', 'position':'relative'});
        $('select[name*="CAT_VEHICLE"]').find('option[value="5"]').hide();
        $('select[name*="CAT_VEHICLE"]').find('option[value="6"]').hide();
        $('select[name*="CAT_VEHICLE"]').find('option[value="3"]').show();
        $('select[name*="CAT_VEHICLE"]').find('option[value="4"]').show();
    }
    this.toggleVehicleParcTypeQuestion = () => {
        if (pthis.getSelectedWorkId() == 219) {
            if ($('select[name*=PARC_TYPE]').val() == 3) {
                showNbrVehicle();
            } else {
                hideNbrVehicle()
            }
            $('select[name*=PARC_TYPE]').on('change', function () {
                $('select[name*="CAT_VEHICLE"]').find('option:selected').prop("selected", false);
                if ($(this).val() == 3) {
                    showNbrVehicle();
                } else {
                    hideNbrVehicle();
                }
            });
        }
    }

    //TRA-EQ-126
    this.resetTraEq126TotalHours = () => {
        if (pthis.getSelectedWorkId() == 335 && $('select[name*=BATEAU_TYPE]').val() == 1) {
            totalUsedHoursElm = $('input[name*=TOTAL_USED_HOURS]');
            if (parseInt(totalUsedHoursElm.val()) > 275) {
                totalUsedHoursElm.val(275)
            }
        }
    }

    // bar-th-177
    this.submitInput.click(function (e) {
        e.preventDefault();
        if ($('input[name*=HORS_RRC]:checked').val() == 1 && pthis.getSelectedWorkId() == 343) {
            if ($('#hors_rrc-error-modal').length > 0) {
                setTimeout(function () {
                    $('#hors_rrc-error-modal').dialogMp();
                }, 1000);
                $('input[name*=HORS_RRC]').siblings('label').find('.radio-btn').css('border-color','#F44336')
                return;
            }
        }
        if ($('input[name*=NB_APPART]').val() < 3 && pthis.getSelectedWorkId() == 343) {
            if ($('#nb_appart-error-modal').length > 0) {
                setTimeout(function () {
                    $('#nb_appart-error-modal').dialogMp();
                }, 1000);
                $('input[name*=NB_APPART]').parent().parent().addClass('error');
                return;
            }
        }
        if ($('input[name*=SURF_TOT]').val() > 10000 && pthis.getSelectedWorkId() == 344) {
            if ($('#surf_tot-error-modal').length > 0) {
                setTimeout(function () {
                    $('#surf_tot-error-modal').dialogMp();
                }, 1000);
                $('input[name*=SURF_TOT]').parent().parent().addClass('error');
                return;
            }
        }
        if (pthis.getSelectedWorkId() == 171 && parseInt($("input[name*='NLIGHT]']").val()) < 10) { //RES-EC-104
            if ($('#nlight-control-modal').length > 0) {
                setTimeout(function () {
                    $('#nlight-control-modal').dialogMp();
                }, 1000);
                $("input[name*='NLIGHT]']").parent().parent().addClass('error');
                return;
            }
        }

        if (pthis.getSelectedWorkId() == 335 && $.inArray($('select[name*=DEPT]').val(), ['20', '21', '97', '98', '99', '100', '101', '102', '103', '104']) !== -1 ) {//BAT-TH-158 && outre mer
            if ($('#bat-th-158-outre-mer-error-modal').length > 0) {
                setTimeout(function () {
                    $('#bat-th-158-outre-mer-error-modal').dialogMp();
                }, 1000);
                $('select[name*=DEPT]').addClass('error');
                return;
            }
        }



        var date_31_12_2024 = new Date(2024,11,31); // month between 0-11
        if (pthis.getSelectedWorkId() == 317 &&  new Date() < date_31_12_2024) { //bar-th-163 be closed on 01/01/2025
            var quotationDate = pthis.inputsContainer.find("input[name*=quotation_date]");
            var qDVal = quotationDate.length > 0 ? quotationDate.val().replace(' / ', '-').replace(' / ', '-') : null;
            var result = /^(\d{2})-(\d{2})-(\d{4})$/.exec(qDVal);
            if (result) {
                var quotationDateVal = new Date(
                    parseInt(result[3], 10),
                    parseInt(result[2], 10) - 1,
                    parseInt(result[1], 10)
                );
            }

            $('#bar-th-163-closing-error-modal').dialogMp();

            if ($('#bar-th-163-closing-error-modal').length > 0 && quotationDateVal > date_31_12_2024) {
                quotationDate.parent().parent().addClass('error');
                return;
            }

        }
        //
        if ($.inArray(ShoppingCart.getSubdomain(), ['pro', 'www', 'entreprises']) > -1) {
            var targetUserId = $('input:hidden[name*=target_user_id]').val();

            let response;

            if (typeof targetUserId === "undefined" || targetUserId.length == 0) {
                response = ShoppingCart.simulationAlreadyInCart(pthis.getSelectedWorkId(), "nouveau");
            } else if (targetUserId.length > 0) {
                response = ShoppingCart.simulationAlreadyInCart(pthis.getSelectedWorkId(), targetUserId);
            }

            pthis.resetTraEq126TotalHours();

            if (!response.exists || window.location.href.indexOf(response.item_id) > -1) {
                // Trick pour verifier la valeur du champ is_entreprise
                pthis.verifyEntrepriseValue();
                if (pthis.getSelectedWorkId() == 317 &&  new Date() < date_31_12_2024) { //bar-th-163 be closed on 01/01/2025
                    setTimeout(function () {
                        pthis.form.submit();
                    }, 4000);
                } else {
                    pthis.form.submit();
                }
            } else {
                ShoppingCart.showExistingSimulationInCartPopUp(response.edit_url, response.eligible_work_title);
            }
        } else {
            // Trick pour verifier la valeur du champ is_entreprise
            pthis.verifyEntrepriseValue();
            if (pthis.getSelectedWorkId() == 317 &&  new Date() < date_31_12_2024) { //bar-th-163 be closed on 01/01/2025
                setTimeout(function () {
                    pthis.form.submit();
                }, 4000);
            } else {
                pthis.form.submit();
            }
        }
    });

    this.toggleRaccordResChaleurQuestion = (e) => {
        if ($('input[name="ash_administration[HEAT_NETWORK_CONNECTION]"]').val() == 1) {
            $('input[name="ash_administration[IS_CONNECTION_CERTIFIED]"]').show();
        } else {
            $('input[name="ash_administration[IS_CONNECTION_CERTIFIED]"]').hide();
        }
    }

    // On souhaite initialiser l'affichage à l'instanciation
    this.refresh();
};
